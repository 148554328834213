import $ from 'jquery'
const $doc = $(document)

function expandCollapseFilters(event) {
  let el = $(this);
  let parent = el.parent().parent();
  let list = parent.find('ul');
  
  parent.toggleClass('active');
  if ( parent.hasClass('active') ) {
    el.attr('aria-expanded', 'true');
    list.removeAttr('hidden');
  } else {
    el.attr('aria-expanded', 'false');
    list.attr('hidden', 'true');
  }
}

function ajaxLoadMorePosts(page = 2, audience = '', topic = '', format = '', search = '') {
  const data = {
    page: page,
    audience: audience,
    topic: topic,
    format: format,
    search: search,
    action: 'ajax_posts_loadmore'
  };

  $.ajax({
    url: php_vars.ajax_url,
    type: "POST",
    dataType: 'html',
    data,
    beforeSend: function () {
      $('body').find('.js-load-more').text('Loading...');
    },
    success: function (htmlResponse) {
      if (htmlResponse == "" || htmlResponse == null) {
        $('body').find('.js-load-more').remove();
      }
      else {
        $('body').find('#library-grid').append(htmlResponse);
      }
    },
    error: function () {
      $('body').find('.js-load-more').remove();
    },
    complete: function () {
      let maxpage = $('body').find('.js-load-more').data('max');
      console.log(page);
      console.log(maxpage);
      if ( page == maxpage ) {
        $('body').find('.js-load-more').remove();
      } else {
        $('body').find('.js-load-more').text('View More');
      }
    }
  });
}

function ajaxLoadPosts(page = 1, audience = '', topic = '', format = '', search = '') {
  const data = {
    page: page,
    audience: audience,
    topic: topic,
    format: format,
    search: search,
    action: 'ajax_posts_filter'
  };

  $.ajax({
    url: php_vars.ajax_url,
    type: "POST",
    dataType: 'html',
    data,
    beforeSend: function () {
      $('body').find('#learn-library-wrap').css('opacity', 0);
      $("#learn-library__loading").show();
    },
    success: function (htmlResponse) {
      if (htmlResponse == "" || htmlResponse == null) {
        $('body').find('#learn-library-wrap').html('<p class="no-results">No results found, please try a different combination of filters and search terms.</p>');
      }
      else {
        $('body').find('#learn-library-wrap').html(htmlResponse);
      }
    },
    error: function () {
      $('body').find('#learn-library-wrap').html('<p class="no-results">Sorry, results found.</p>');
      $("#learn-library__loading").hide();
    },
    complete: function () {
      $('body').find('#learn-library-wrap').css('opacity', 1);
      $("#learn-library__loading").hide();
    }
  });
}

function filterPosts() {
  let page = 2;
  let audience;
  let topic;
  let format;
  let search;
  if ('URLSearchParams' in window) {
    var searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('search')) {
      search = searchParams.get('search');
      $('#searchterm').val(search);
    }
    if (searchParams.has('topic')) {
      topic = searchParams.get('topic');
      $('.js-topic-filter').removeClass('active');
      $('.js-topic-filter[data-topic="'+topic+'"]').addClass('active');
    }
    if (searchParams.has('audience')) {
      audience = searchParams.get('audience');
      $('.js-audience-filter').removeClass('active');
      $('.js-audience-filter[data-audience="' + audience + '"]').addClass('active');
    }
    if (searchParams.has('search') || searchParams.has('topic') || searchParams.has('audience') ) {
      ajaxLoadPosts(1, audience, topic, format, search);
    }
  }
  $('.js-search-input-filter').on('keyup', function (event) {
    search = $('.js-search-input-filter').val();
    ajaxLoadPosts(1, audience, topic, format, search);
    page = 2;
  });
  $('.js-search-filter').on('click', function (event) {
    search = $('.js-search-input-filter').val();
    ajaxLoadPosts(1, audience, topic, format, search);
    page = 2;
  });
  $('.js-audience-filter').on('click', function (event) {
    $('.js-audience-filter').removeClass('active');
    $(this).addClass('active');
    audience = $(this).data('audience');
    ajaxLoadPosts(1, audience, topic, format, search);
    page = 2;
  });
  $('.js-topic-filter').on('click', function (event) {
    $('.js-topic-filter').removeClass('active');
    $(this).addClass('active');
    topic = $(this).data('topic');
    ajaxLoadPosts(1, audience, topic, format, search);
    page = 2;
  });
  $('.js-format-filter').on('click', function (event) {
    $('.js-format-filter').removeClass('active');
    $(this).addClass('active');
    format = $(this).data('format');
    ajaxLoadPosts(1, audience, topic, format, search);
    page = 2;
  });
  $doc.on('click', '.js-load-more', function() {
    ajaxLoadMorePosts(page, audience, topic, format, search);
    page++;
  });
}

export default function initLearnLibrary(){
  if ($('body').hasClass('page-template-learn-library')) {
    $(document).ready(filterPosts);
  }
  $doc.on('click', '.js-expand-collapse', expandCollapseFilters )
}
