import $ from 'jquery'

function init(){
  interactiveTooltips();
  initAccordion();
}

function initAccordion(){
  $(".interactive-accordion__btn").on('click', function(){
    if(!$(this).hasClass('active-accordion')){
      $(this).addClass('active-accordion');
      $(this).find('span').addClass('rotate');
      $(this).parent().find('.interactive-accordion__item-inner').addClass('visible');
    }
    else{
      $(this).find("span").removeClass('rotate');
      $(this).parent().find('.interactive-accordion__item-inner').removeClass('visible');
      $(this).removeClass('active-accordion');
    }
  })
}

function interactiveTooltips(){
  $(".interactive-table__circle").closest(".interactive-accordion__part").addClass('has-circle');

  $(".has-circle span").on('click', function(){
    $(this).parent().find(".interactive-table__circle-tooltip").show();
  });

  $(".interactive-table__circle-tooltip a").on('click', function(e){
    e.stopPropagation();
    $(this).parent().hide();
  })

  $(".interactive-table__circle").on('click', function(){
    $(this).find(".interactive-table__circle-tooltip").show();
  });
}

export default function initInteractiveAccordion(){
  $(document).ready( init )
}
