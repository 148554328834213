import $ from 'jquery'

const $doc = $(document)

function filterTeam(event) {
  let el = $(this);
  let cat = el.data('cat');

  event.preventDefault()
  $('.js-team-filter').removeClass('active');
  el.addClass('active');

  if ( cat == 'all' ) {
    $('.js-team-item').addClass('active');
  } else {
    $('.js-team-item').removeClass('active');
    var members =  $('.js-team-item');

    jQuery.each( members, function( i, val ) {
      var member = $(this);
      var memberCats = $(this).attr('data-cat');
      var memberCatsArr = memberCats.split(',');
      jQuery.each( memberCatsArr, function( i, val ) {
        if(cat === val) $(member).addClass('active');
      });
    });
  }
}

function findMembers(cat){
  $('.js-team-item[data-cat="' + cat + '"]').addClass('active');
}

export default function initTeamFilters(){
  $doc.on('click', '.js-team-filter', filterTeam )
}
