import $ from 'jquery'
const $doc = $(document)

function ajaxLoadMoreResources(page = 1, cat = '', order = '', search = '') {
  const data = {
    page: page,
    cat: cat,
    order: order,
    search: search,
    action: 'ajax_resources_loadmore'
  };

  $.ajax({
    url: php_vars.ajax_url,
    type: "POST",
    dataType: 'html',
    data,
    beforeSend: function () {
      $('body').find('.js-load-more-resources').text('Loading...');
    },
    success: function (htmlResponse) {
      if (htmlResponse == "" || htmlResponse == null) {
        $('body').find('.js-load-more-resources').remove();
      }
      else {
        $('body').find('#resources-grid').append(htmlResponse);
      }
    },
    error: function () {
      $('body').find('.js-load-more-resources').remove();
    },
    complete: function () {
      let maxpage = $('body').find('.js-load-more-resources').data('max');
      console.log(page);
      console.log(maxpage);
      if (page == maxpage) {
        $('body').find('.js-load-more-resources').remove();
      } else {
        $('body').find('.js-load-more-resources').text('View More');
      }
    }
  });
}

function ajaxLoadResources(page = 1, cat = '', order = '', search = '') {
  const data = {
    page: page,
    cat: cat,
    order: order,
    search: search,
    action: 'ajax_resources_filter'
  };

  $.ajax({
    url: php_vars.ajax_url,
    type: "POST",
    dataType: 'html',
    data,
    beforeSend: function () {
      $('body').find('#resources-wrap').css('opacity', 0);
    },
    success: function (htmlResponse) {
      if (htmlResponse == "" || htmlResponse == null) {
        $('body').find('#resources-wrap').html('<p class="no-results">No results found, please try a different combination of filters and search terms.</p>');
      }
      else {
        $('body').find('#resources-wrap').html(htmlResponse);
      }
    },
    error: function () {
      $('body').find('#resources-wrap').html('<p class="no-results">Sorry, no results found.</p>');
    },
    complete: function () {
      $('body').find('#resources-wrap').css('opacity', 1);
    }
  });
}

function filterPosts() {
  let page = 2;
  let cat;
  let search;
  let order;
  if ('URLSearchParams' in window) {
    var searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('search')) {
      search = searchParams.get('search');
      $('#searchterm').val(search);
    }
    if (searchParams.has('cat')) {
      topic = searchParams.get('cat');
      $('.js-topic-filter[data-topic="' + topic + '"]').addClass('active');
    }
    if (searchParams.has('search') || searchParams.has('cat')) {
      ajaxLoadResources(1, cat, order, search);
    }
  }
  $('.js-search-resource-input-filter').on('keyup', function (event) {
    search = $('.js-search-resource-input-filter').val();
    ajaxLoadResources(1, cat, order, search);
    page = 2;
  });
  $('.js-search-resource-filter').on('click', function (event) {
    search = $('.js-search-resource-input-filter').val();
    ajaxLoadResources(1, cat, order, search);
    page = 2;
  });
  $('.js-cat-filter').on('click', function (event) {
    $('.js-cat-filter').removeClass('active');
    $(this).addClass('active');
    cat = $(this).data('cat');
    ajaxLoadResources(1, cat, order, search);
    page = 2;
  });

  $('#js-resources-order').on('change', function () {
    console.log(this.value);
    order = this.value;
    ajaxLoadResources(1, cat, order, search);
  });

  $doc.on('click', '.js-load-more-resources', function () {
    ajaxLoadMoreResources(page, cat, search);
    page++;
  });
}

export default function initNewsEventsFilters() {
  if ($('body').hasClass('page-template-resources')) {
    $(document).ready(filterPosts);
  }
}
